export const DATA_PROJECTS = [
  {
    id: 'project-cover',
    header: '',
    body: '',
    badges: [],
    image: '',
    linkDemo: '',
    linkGithub: '',
  },
  {
    id: 'project-1',
    header: 'Makans',
    body: 'The ultimate online restaurant solution. Easily manage your cart, customize food choices, navigate categories effortlessly, and enjoy quick pagination. Precise shipping costs via RajaOngkir and secure payments with Midtrans.',
    badges: [
      'Vue',
      'Tailwind CSS',
      'JavaScript',
      'Express',
      'Sequelize',
      'PostgreSQL',
      'Firebase',
      'Supabase',
    ],
    image: '../../assets/project-image-1.png',
    linkDemo: 'https://makans.web.app/',
    linkGithub: 'https://github.com/yossyadirta/makans-client',
  },
  {
    id: 'project-2',
    header: 'Kitchef',
    body: 'Your mobile e-commerce platform for discovering recipes and purchasing ingredients hassle-free. Explore recipes, make payments, track orders, and live chat. With Google Maps, Midtrans, and TalkJS APIs, enjoy a seamless, user-friendly shopping and cooking experience.',
    badges: [
      'JavaScript',
      'React Native',
      'Expo',
      'TalkJS',
      'Express',
      'Sequelize',
      'PostgreSQL',
      'Apollo',
      'GraphQL',
      'Redis',
      'Supabase',
    ],
    image: '../../assets/project-image-2.png',
    linkDemo: 'https://expo.dev/@yossyadirta/client-user?serviceType=c',
    linkGithub: 'https://github.com/kitchef-app',
  },
  {
    id: 'project-3',
    header: 'Jobhunt',
    body: 'Your mobile e-commerce platform for discovering recipes and purchasing ingredients hassle-free. Explore recipes, make payments, track orders, and live chat. With Google Maps, Midtrans, and TalkJS APIs, enjoy a seamless, user-friendly shopping and cooking experience.',
    badges: [
      'JavaScript',
      'React Native',
      'Expo',
      'TalkJS',
      'Express',
      'Sequelize',
      'PostgreSQL',
      'Apollo',
      'GraphQL',
      'Redis',
      'Supabase',
    ],
    image: '../../assets/project-image-3.png',
    linkDemo: 'https://jobhunt-indonesia.web.app/',
    linkGithub: 'https://github.com/yossyadirta/jobhunt-web-app',
  },
];

export const VERTICAL_SCROLL_MENU_ITEM: { menu: string; text: string }[] = [
  { menu: 'hero', text: 'Home' },
  { menu: 'about', text: 'About' },
  { menu: 'skills', text: 'Skills' },
  { menu: 'projects', text: 'Projects' },
  { menu: 'contact', text: 'Contact' },
];
